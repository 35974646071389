import React from 'react'
import Layout from '../components/layout'
import logo from '../static/logo.png'

const Four0Four = () => (
  <Layout>
    <div className="bg-white">
      <main className="max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-16">
          <img className="mx-auto block h-24 w-auto"
               width="200"
               height="96"
               src={logo}
               alt="Wax Carvers Logo" />
        </div>
        <div className="max-w-xl mx-auto py-16 sm:py-24">
          <div className="text-center">
            <p className="text-sm font-semibold text-blue-700 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">This page does not exist.</h1>
            <p className="mt-2 text-lg text-gray-500">The page you are looking for could not be found.</p>
          </div>
          <div className="mt-12">
            <h2 className="text-sm font-semibold text-gray-500 tracking-wide uppercase">Popular pages</h2>
            <ul role="list" className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">



              <li className="relative py-6 flex items-start space-x-4">
                <div className="flex-shrink-0">
              <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                <svg className="h-6 w-6 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                </svg>
              </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                  <a href="/classes" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    Classes
                  </a>
                </span>
                  </h3>
                  <p className="text-base text-gray-500">Take a look at available classes</p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </div>
              </li>

              <li className="relative py-6 flex items-start space-x-4">
                <div className="flex-shrink-0">
              <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                <svg className="h-6 w-6 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 5c7.18 0 13 5.82 13 13M6 11a7 7 0 017 7m-6 0a1 1 0 11-2 0 1 1 0 012 0z" />
                </svg>
              </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                  <a href="/blog" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    Blog
                  </a>
                </span>
                  </h3>
                  <p className="text-base text-gray-500">Read our latest tips and articles</p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </div>
              </li>
              <li className="relative py-6 flex items-start space-x-4">
                <div className="flex-shrink-0">
              <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                <svg className="h-6 w-6 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                </svg>
              </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                  <a href="/faq" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    Frequently asked questions
                  </a>
                </span>
                  </h3>
                  <p className="text-base text-gray-500">See questions and answers about wax carvers</p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </div>
              </li>

              <li className="relative py-6 flex items-start space-x-4">
                <div className="flex-shrink-0">
              <span className="flex items-center justify-center h-12 w-12 rounded-lg bg-indigo-50">
                <svg className="h-6 w-6 text-blue-700" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                </svg>
              </span>
                </div>
                <div className="min-w-0 flex-1">
                  <h3 className="text-base font-medium text-gray-900">
                <span className="rounded-sm focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                  <a href="/contact" className="focus:outline-none">
                    <span className="absolute inset-0" aria-hidden="true"></span>
                    Contact
                  </a>
                </span>
                  </h3>
                  <p className="text-base text-gray-500">Contact us for more information</p>
                </div>
                <div className="flex-shrink-0 self-center">
                  <svg className="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                  </svg>
                </div>
              </li>
            </ul>
            <div className="mt-8">
              <a href="/" className="text-base font-medium text-blue-700 hover:text-indigo-500">Or go back home<span aria-hidden="true"> &rarr;</span></a>
            </div>
          </div>
        </div>
      </main>
    </div>

  </Layout>
)

export default Four0Four
